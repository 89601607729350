import {useState, createContext, useEffect} from 'react'

export const TabsContext = createContext(false)

export const TabsProvider = ({children}) => {
  const [userId, setUserId] = useState()
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('activeTab') || 'home'
  })
  const [currentView, setCurrentView] = useState('job-posts')
  const [currentClassesView, setCurrentClassesView] = useState('classes-list')
  const [unreadChats, setUnreadChats] = useState()
  const [exploreTab, setExploreTab] = useState()

  useEffect(() => {
    localStorage?.setItem('activeTab', activeTab)
  }, [activeTab])

  const toggleActiveTabToHome = () => {
    setActiveTab('home')
  }
  const toggleActiveTabToBrowseJobs = () => {
    setActiveTab('browse-jobs')
  }
  const toggleActiveTabToAppliedJobs = () => {
    setActiveTab('applied-jobs')
  }
  const toggleActiveTabToSavedJobs = () => {
    setActiveTab('saved-jobs')
  }
  const toggleActiveTabToJobPosts = () => {
    setActiveTab('job-posts')
  }
  const toggleActiveTabToFavoriteApplicants = () => {
    setActiveTab('favorite-applicants')
  }
  const toggleActiveTabToFavoriteBusinesses = () => {
    setActiveTab('favorite-businesses')
  }
  const toggleActiveTabToSettings = () => {
    setActiveTab('settings')
  }
  const toggleActiveTabToExplore = () => {
    setActiveTab('explore')
  }
  const toggleActiveTabToClasses = () => {
    setActiveTab('classes')
  }
  const toggleActiveTabToAppliedClasses = () => {
    setActiveTab('applied-classes')
  }
  const toggleViewToJobPosts = () => {
    setCurrentView('job-posts')
  }
  const toggleViewToJobPost = () => {
    setCurrentView('job-post')
  }
  const toggleViewToCreateJobPost = () => {
    setCurrentView('create-job-post')
  }
  const toggleViewToEditJobPost = () => {
    setCurrentView('edit-job-post')
  }
  const toggleActiveTabToProfiles = () => {
    setActiveTab('profiles')
  }
  const toggleExploreToBusinesses = () => {
    setExploreTab('businesses')
  }
  const toggleExploreToProfessionals = () => {
    setExploreTab('professionals')
  }
  const toggleClassesViewToCreateClass = () => {
    setCurrentClassesView('create-class')
  }
  const toggleClassesViewToAllClasses = () => {
    setCurrentClassesView('classes-list')
  }
  const toggleClassesViewToClassPost = () => {
    setCurrentClassesView('class-post')
  }
  const toggleClassesViewToEditClassPost = () => {
    setCurrentClassesView('edit-class-post')
  }

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        toggleActiveTabToHome,
        toggleActiveTabToBrowseJobs,
        toggleActiveTabToAppliedJobs,
        toggleActiveTabToAppliedClasses,
        toggleActiveTabToSavedJobs,
        toggleActiveTabToJobPosts,
        toggleActiveTabToFavoriteApplicants,
        toggleActiveTabToFavoriteBusinesses,
        toggleActiveTabToSettings,
        toggleActiveTabToClasses,
        toggleClassesViewToCreateClass,
        toggleActiveTabToExplore,
        toggleViewToJobPosts,
        toggleViewToJobPost,
        toggleViewToCreateJobPost,
        toggleViewToEditJobPost,
        toggleActiveTabToProfiles,
        toggleExploreToBusinesses,
        toggleExploreToProfessionals,
        exploreTab,
        currentView,
        setCurrentView,
        setUserId,
        userId,
        unreadChats,
        setUnreadChats,
        currentClassesView,
        toggleClassesViewToAllClasses,
        toggleClassesViewToClassPost,
        toggleClassesViewToEditClassPost,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}
