import axios from 'axios'
import {removeItemFromStorage, setDataInStorage} from 'utils/storage'

const proxy = process.env.REACT_APP_STAGING_API_URL

export const getToken = () => JSON.parse(localStorage.getItem('user') || '{}')

export const registerUser = async (data) => {
  const response = await axios(proxy + '/register', {
    method: 'POST',
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const verifyUser = async (verificationCode) => {
  const response = await axios(
    proxy + `/verifyEmail?token=${verificationCode}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const loginUser = async (data, tokens) => {
  const {token} = getToken()
  const response = await axios(proxy + '/login', {
    method: 'POST',
    headers: {token: tokens || token},
    data: data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const forgotPassword = async (data) => {
  const response = await axios(proxy + '/forgotPassword', {
    method: 'POST',
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const resetPassword = async (data) => {
  const response = await axios(proxy + `/resetPassword`, {
    method: 'POST',
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const resendVerification = async (data) => {
  const response = await axios(proxy + `/verifyEmail`, {
    method: 'POST',
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getMyProfile = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/users/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    setDataInStorage({
      name: 'profile',
      data: response.data,
    })
    return response.data
  }
  return null
}

export const logoutUser = async ({sessionId, token}) => {
  const response = await axios(proxy + '/logout', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      sessionId,
    },
  })
  if (response && response.data) {
    removeItemFromStorage({name: 'user'})
    return response.data
  }
  return null
}

export const completeProfile = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/users', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const uploadFile = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/profile`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAvatar = async (id, size) => {
  let url = proxy + `/medias/avatar/${id}`
  if (size) url += `?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getPublicAvatar = async (id, size) => {
  let url = proxy + `/medias/avatar/${id}`
  if (size) url += `?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getBusinessImg = async (id) => {
  const response = await axios(proxy + `/medias/logo/${id}`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getPublicBusinessImg = async (id) => {
  const response = await axios(proxy + `/medias/logo/${id}`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getPortfolioInfo = async (id) => {
  const response = await axios(proxy + `/users/${id}/portfolio`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getFile = async ({id, type, size}) => {
  let url = proxy + `/medias/profile/${id}?type=${type}`
  if (size) url += `&width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getUsers = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/users', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users: users, totalCount}
  }
  return null
}

export const addToFavorites = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/favorites/users/${id}/add`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const removeFromFavorites = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/favorites/users/${id}/remove`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteFromPortfolio = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getUser = async (id) => {
  const response = await axios(proxy + `/users/${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getLoggedInViewUser = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/users/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const initializeJobPost = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/jobs/posts', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createJobPost = async ({id, jobData}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/jobs/posts/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: jobData,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getCreatedJobPosts = async (pageNumber, size = 10, status) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/jobs/posts?from=${from + 1}&size=${size}`
  if (status && status !== 'all') url += `&status=${status}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {jobPosts, totalCount} = response.data
    return {jobPosts, totalCount}
  }
  return null
}

export const getJobPosts = async (pageNumber, size = 10, title) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/jobs/posts/active/all?from=${from + 1}&size=${size}`
  if (title && title !== 'all') url += `&title=${title}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {jobPosts, totalCount} = response.data
    return {jobPosts, totalCount}
  }
  return null
}

export const getLatestJobPosts = async () => {
  const {token} = getToken()
  let url = proxy + `/jobs/posts/active/latest`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {jobPosts, totalCount} = response.data
    return {jobPosts, totalCount}
  }
  return null
}

export const getJobPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/jobs/posts/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getJobPostPublic = async (id) => {
  const response = await axios(proxy + `/jobs/posts/${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const applyForJob = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/jobs/posts/${id}/applications`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getJobApplications = async (id, pageNumber, size) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/jobs/posts/${id}/applications?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {applications, totalCount} = response.data
    return {applications, totalCount}
  }
  return null
}

export const getFavoriteApplicants = async (pageNumber, size) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/favorites/users?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {favorites, totalCount} = response.data
    return {favorites, totalCount}
  }
  return null
}

export const acceptApplication = async (id) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/jobs/posts/applications/${id}/accept`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const rejectApplication = async (id) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/jobs/posts/applications/${id}/reject`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAppliedJobs = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/jobs/applications/applied?from=${from + 1}&size=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {applications, totalCount} = response.data
    return {applications, totalCount}
  }
  return null
}

export const getSavedJobPosts = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/favorites/jobs?from=${from + 1}&size=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {favorites, totalCount} = response.data
    return {favorites, totalCount}
  }
  return null
}

export const saveJobPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/favorites/jobs/${id}/add`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const unsaveJobPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/favorites/jobs/${id}/remove`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getNotificationsCount = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/count`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {unreadCount, totalCount} = response.data
    return {unreadCount, totalCount}
  }
  return null
}

export const getNotifications = async (pageNumber = 1, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/notifications?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {notifications, totalCount} = response.data
    return {notifications, totalCount}
  }
  return null
}

export const markNotificationAsRead = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/${id}/read`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const markAllNotificationsAsRead = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/read`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createConversation = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/conversations', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getConversations = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getConversation = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations/${id}/message`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const markConversationAsRead = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations/${id}/read`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteConversation = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const sendMessage = async ({id, data}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations/${id}/message`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const addJobPosition = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/experiences`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const updateJobPosition = async ({id, data}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/experiences/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteJobPosition = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/experiences/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const updatePassword = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/users/updatePassword', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deactivateUser = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/users', {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteJobPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/jobs/posts/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAllUsers = async (
  pageNumber,
  size = 10,
  searchTerm,
  profession,
  role,
  businessType
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/users?from=${from + 1}&size=${size}`
  if (role) url += `&role=${role}`
  if (searchTerm) url += `&search=${searchTerm}`
  if (profession) url += `&profession=${profession}`
  if (businessType) url += `&businessType=${businessType}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users: users, totalCount}
  }
  return null
}

export const searchJobPosts = async (
  pageNumber,
  size = 10,
  searchTerm,
  employmentType
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/jobs/posts/active/all?from=${from + 1}&size=${size}`
  if (searchTerm) url += `&search=${searchTerm}`
  if (employmentType) url += `&employmentType=${employmentType}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {jobPosts, totalCount} = response.data
    return {jobPosts, totalCount}
  }
  return null
}

export const subscribe = async (data) => {
  const response = await axios(proxy + `/waitlist`, {
    method: 'POST',
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getConversationsCount = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/conversations/count`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {unreadCount} = response.data
    return {unreadCount}
  }
  return null
}

export const facebookLogin = async (data, tokens) => {
  const {token} = getToken()
  const response = await axios(proxy + '/facebookSignIn', {
    method: 'POST',
    headers: {token: tokens || token},
    data: data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const addRole = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/users/updateRole', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getCertificatesInfo = async (id) => {
  const response = await axios(proxy + `/users/${id}/certificate`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAllProfessionals = async (
  pageNumber,
  size = 10,
  searchTerm,
  profession
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/users?from=${from + 1}&size=${size}&role=job_seeker`
  if (searchTerm) url += `&search=${searchTerm}`
  if (profession) url += `&profession=${profession}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users: users, totalCount}
  }
  return null
}

export const addRecommendation = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/recommendations`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getRecommendations = async (id, pageNumber, size = 1) => {
  const from = pageNumber * size - size
  let url = proxy + `/recommendations/${id}?from=${from + 1}&size=${size}`
  const response = await axios(url, {
    method: 'GET',
  })
  if (response && response.data) {
    const {recommendations, totalCount} = response.data
    return {recommendations: recommendations, totalCount}
  }
  return null
}

export const deleteRecommendation = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/recommendations/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const editPortfolioDescription = async ({fileId, description}) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/medias/profile/${fileId}/description`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {description},
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getPortfolioInfoPrivate = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/users/${id}/portfolio`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const likeFile = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/profile/${id}/likes`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const unlikeFile = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/profile/${id}/likes`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const initializeClass = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/classes/posts', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createClass = async ({id, classData}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: classData,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const uploadPromoImg = async ({formData}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/class`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAllClasses = async (pageNumber, size = 10, status) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/classes/posts?from=${from + 1}&size=${size}`
  if (status) url += `&status=${status}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {classPosts, totalCount} = response.data
    return {classPosts, totalCount}
  }
  return null
}

export const getClassPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getClassLogo = async (id, size) => {
  let url = proxy + `/medias/classes/logo/${id}`
  if (size) url += `type=logo?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getClassFile = async (id, size) => {
  let url = proxy + `/medias/class/${id}?type=logo`
  if (size) url += `&width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getClassApplications = async (id, pageNumber, size) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/classes/posts/${id}/applications?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {applications, totalCount} = response.data
    return {applications, totalCount}
  }
  return null
}

export const getLatestClassPosts = async () => {
  const {token} = getToken()
  let url = proxy + `/classes/posts/active/latest`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {classPosts, totalCount} = response.data
    return {classPosts, totalCount}
  }
  return null
}

export const getClassPosts = async (pageNumber, size = 10, title) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/classes/posts/active/all?from=${from + 1}&size=${size}`
  if (title && title !== 'all') url += `&category=${title}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {classPosts, totalCount} = response.data
    return {classPosts, totalCount}
  }
  return null
}

export const signUpForClass = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/applications`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const cancelClassApplication = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/applications`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const searchClassPosts = async (
  pageNumber,
  size = 10,
  searchTerm,
  category
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/classes/posts/active/all?from=${from + 1}&size=${size}`
  if (searchTerm) url += `&search=${searchTerm}`
  if (category) url += `&category=${category}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {classPosts, totalCount} = response.data
    return {classPosts, totalCount}
  }
  return null
}

export const getAppliedClasses = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url =
    proxy + `/classes/applications/applied?from=${from + 1}&size=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {applications, totalCount} = response.data
    return {applications, totalCount}
  }
  return null
}

export const getClassApplicants = async (id, pageNumber, size) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/classes/posts/${id}/participants?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {participants, totalCount} = response.data
    return {participants, totalCount}
  }
  return null
}

export const getClassReviews = async (id, pageNumber, size) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/reviews/${id}?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {reviews, totalCount, totalRating} = response.data
    return {reviews, totalCount, totalRating}
  }
  return null
}

export const saveCustomMessage = async ({id, message}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/message`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {message},
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const selectAttendees = async ({id, participants}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/applications`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {participants},
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const removeReview = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/reviews/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const postReview = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/reviews', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getExistingCertificatesInfo = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/certificate`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getClassCertificateFile = async ({id, type, size}) => {
  let url = proxy + `/medias/class/${id}?type=${type}`
  if (size) url += `&width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getClassPhotosInfoPrivate = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/classes/posts/${id}/portfolio`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getClassPhotosInfo = async (id) => {
  const response = await axios(proxy + `/classes/posts/${id}/portfolio`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const searchClassApplicants = async (id, pageNumber, size, search) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy +
      `/classes/posts/${id}/participants?from=${from + 1}&size=${size}&search=${search}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {participants, totalCount} = response.data
    return {participants, totalCount}
  }
  return null
}
